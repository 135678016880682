import { createAsyncThunk } from '@reduxjs/toolkit'
import { IGeo } from '../../../../../shared/api/geo'
import { getGeo } from '../../../../../shared/api/geo/geo'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

export const fetchGeo = createAsyncThunk<
	IGeo[],
	void,
	{ rejectValue: RejectedDataType }
>('geo/fetchGeo', async (_, thunkAPI) => {
	try {
		const response = await getGeo()
		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
