import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

import { addOffer } from '../../../../../shared/api/offer/offer'
import { IOfferAdd } from '../../../../../shared/api/offer/types'
import { fetchOfferThunk } from '../get/offerThunk'

export const addOfferThunk = createAsyncThunk<
	IOfferAdd,
	IOfferAdd, // Заменяем `void` на `IGeo`, чтобы передавать данные
	{ rejectValue: RejectedDataType }
>('geo/AddGeo', async (offerData: IOfferAdd, thunkAPI) => {
	try {
		const response = await addOffer(offerData)

		// Вызываем другой thunk после успешного выполнения
		thunkAPI.dispatch(fetchOfferThunk())

		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
