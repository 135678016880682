import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { GeoPage } from '../../pages/geo'
import { Offer } from '../../pages/offer'
import { OfferPage } from '../../pages/offerOne'
import { Fallback } from '../../shared/ui/fallback'
import { OfferPageNotFound } from '../../widgets/offerNotFound'
import { Layout } from '../layout/layout'

export const AppRouter = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Navigate to='/offer' replace />} />
					<Route path='geo' element={<GeoPage />} />
					<Route path='offer' element={<Offer />} />

					<Route path='*' element={<Fallback />} />
				</Route>
				<Route path='offers/:id' element={<OfferPage />} />
				<Route path='offers/404' element={<OfferPageNotFound />} />
			</Routes>
		</BrowserRouter>
	)
}
