import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGeo } from '../../../../../shared/api/geo'
import { IGeoState } from '../../types'
import { fetchGeo } from './geoThunk'

const initialState: IGeoState = {
	geo: [
		{
			id: 0,
			code: '',
			name: '',
			currency: '',
			language: '',
		},
	],
	loading: false,
	error: null,
}
const geoSlice = createSlice({
	name: 'geo',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(fetchGeo.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(fetchGeo.fulfilled, (state, action: PayloadAction<IGeo[]>) => {
				state.geo = action.payload
				state.loading = false
				state.error = null
			})
			.addCase(fetchGeo.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload ?? null
			}),
})

export default geoSlice.reducer
