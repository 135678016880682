import { createAsyncThunk } from '@reduxjs/toolkit'
import { addGeo } from '../../../../../shared/api/geo/geo'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'
import { fetchGeo } from '../get/geoThunk'
import { IGeo } from './../../../../../shared/api/geo/types'

export const addGeoThunk = createAsyncThunk<
	IGeo,
	IGeo, // Заменяем `void` на `IGeo`, чтобы передавать данные
	{ rejectValue: RejectedDataType }
>('geo/AddGeo', async (geoData: IGeo, thunkAPI) => {
	try {
		const response = await addGeo(geoData)

		// Вызываем другой thunk после успешного выполнения
		thunkAPI.dispatch(fetchGeo())

		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
