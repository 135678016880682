import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IOfferOneState } from '../../types'

import { IOffer } from '../../../../../shared/api/offer'
import { fetchOfferOneThunk } from './offerThunk'

const initialState: IOfferOneState = {
	offer: {
		id: 0,
		name: '',
		geoId: 0,
		price: '',
		discount: '',
		previewImageUrl: '',
		link: '',
		createdAt: '',
		updatedAt: '',
		buttonDescription: '',
		description: '',
		geo: {
			code: '',
			name: '',
			currency: '',
			language: '',
			id: 0,
		},
		Form: [
			{
				id: 0,
				offerId: 0,
				name: '',
				template: '',
				createdAt: '',
				updatedAt: '',
			},
		],
	},

	loading: false,
	error: null,
}
const offerOneSlice = createSlice({
	name: 'offer',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(fetchOfferOneThunk.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(
				fetchOfferOneThunk.fulfilled,
				(state, action: PayloadAction<IOffer>) => {
					state.offer = action.payload
					state.loading = false
					state.error = null
				}
			)
			.addCase(fetchOfferOneThunk.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload ?? null
			}),
})

export default offerOneSlice.reducer
