import { createSelector } from '@reduxjs/toolkit'

import { IOfferState } from '../../types'

const selectBase = createSelector(
	(state: RootState) => state,
	state => state.offerSlice
)

export const selectOffer = createSelector(
	selectBase,
	(state: IOfferState) => state.offer
)
export const selectOfferLoading = createSelector(
	selectBase,
	(state: IOfferState) => state.loading
)
export const selectOfferError = createSelector(
	selectBase,
	(state: IOfferState) => state.error
)
