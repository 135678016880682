import { useAutocomplete } from '@mui/base/useAutocomplete'
import { styled } from '@mui/system'
import * as React from 'react'
import { IDefaultValue, IDropDown, countries } from '../types' // Import your own types

export const DropDown = ({
	defaultValue,
	countries,
	callSetGeo, // получаем функцию из пропсов
}: IDropDown) => {
	const [value, setValue] = React.useState<IDefaultValue | null>(defaultValue)
	const [inputValue, setInputValue] = React.useState('')

	React.useEffect(() => {
		setValue(defaultValue)
	}, [defaultValue])

	const {
		getRootProps,
		getInputProps,
		getListboxProps,
		getInputLabelProps,
		getOptionProps,
		groupedOptions,
		focused,
	} = useAutocomplete<countries>({
		id: 'controlled-state-demo',
		options: countries,
		getOptionLabel: option => option.nameCountries,
		value,
		onChange: (event, newValue) => {
			// Обновляем текущее значение
			setValue(newValue as IDefaultValue)

			// Если есть новое значение, вызываем callSetGeo
			if (newValue) {
				callSetGeo(newValue.nameCountries, newValue.id) // Передаем выбранное имя и id
			}
		},
		inputValue,
		onInputChange: (event, newInputValue) => setInputValue(newInputValue),
		isOptionEqualToValue: (option, value) => option.id === value.id,
	})

	return (
		<Layout>
			<AutocompleteWrapper>
				<Label {...getInputLabelProps()}>Выберите страну</Label>
				<AutocompleteRoot
					{...getRootProps()}
					className={focused ? 'Mui-focused' : ''}
				>
					<Input {...getInputProps()} />
				</AutocompleteRoot>
				{groupedOptions.length > 0 && (
					<Listbox {...getListboxProps()}>
						{(groupedOptions as countries[]).map((option, index) => (
							<Option
								{...getOptionProps({
									option,
									index,
								})}
								key={option.id}
							>
								{option.nameCountries}
							</Option>
						))}
					</Listbox>
				)}
			</AutocompleteWrapper>
		</Layout>
	)
}

const Label = styled('label')`
	display: block;

	font-family: sans-serif;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 4px;
`

const blue = {
	100: '#DAECFF',
	200: '#99CCF3',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	700: '#0059B2',
	900: '#003A75',
}

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
}

const AutocompleteWrapper = styled('div')`
	position: relative;
`

const AutocompleteRoot = styled('div')(
	({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
		theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
	};
  display: flex;
  gap: 5px;
  padding-right: 5px;
  overflow: hidden;
  width: 97%;

  &.Mui-focused {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
			theme.palette.mode === 'dark' ? blue[700] : blue[200]
		};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus-visible {
    outline: 0;
  }
`
)

const Input = styled('input')(
	({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
  flex: 1 0 auto;
`
)

const Listbox = styled('ul')(
	({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  max-width: 320px;
  border-radius: 12px;
  overflow: auto;
  outline: 0;
  max-height: 300px;
  z-index: 10000;
  position: absolute;
  left: 0;
  right: 0;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 6px ${
		theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
	};
  `
)

const Option = styled('li')(
	({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &[aria-selected=true] {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.Mui-focusVisible {
    box-shadow: 0 0 0 3px ${
			theme.palette.mode === 'dark' ? blue[500] : blue[200]
		};
  }

  &[aria-selected=true].Mui-focused,
  &[aria-selected=true].Mui-focusVisible {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }
  `
)

const Layout = styled('div')`
	display: flex;
	flex-flow: column nowrap;
	gap: 4px;
`

const Pre = styled('pre')(({ theme }) => ({
	margin: '0.5rem 0',
	fontSize: '0.75rem',
	'& code': {
		backgroundColor: theme.palette.mode === 'light' ? grey[100] : grey[900],
		border: '1px solid',
		borderColor: theme.palette.mode === 'light' ? grey[300] : grey[700],
		color: theme.palette.mode === 'light' ? '#000' : '#fff',
		padding: '0.125rem 0.25rem',
		borderRadius: 3,
	},
}))
