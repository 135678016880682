import { createAsyncThunk } from '@reduxjs/toolkit'
import { patchOffer } from '../../../../../shared/api/offer/offer'
import { IOfferUpdate } from '../../../../../shared/api/offer/types'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'
import { fetchOfferThunk } from '../get/offerThunk'

export const fetchUpdateOfferThunk = createAsyncThunk<
	IOfferUpdate, // Тип данных, которые возвращает функция
	{ data: IOfferUpdate; id: number }, // Тип передаваемых аргументов
	{ rejectValue: RejectedDataType } // Тип данных для rejectWithValue
>('offer/fetchOffer', async ({ data, id }, thunkAPI) => {
	try {
		const response = await patchOffer(data, id) // Передаем данные и id
		thunkAPI.dispatch(fetchOfferThunk())
		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
