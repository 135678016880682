import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGeoAddState } from '../../types'
import { deleteGeoThunk } from './geoThunk'

const initialState: IGeoAddState = {
	geo: {
		id: 0,
		code: '',
		name: '',
		currency: '',
		language: '',
	},

	loading: false,
	error: null,
}
const geoDeleteSlice = createSlice({
	name: 'geo',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(deleteGeoThunk.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(
				deleteGeoThunk.fulfilled,
				(state, action: PayloadAction<any>) => {
					state.geo = action.payload
					state.loading = false
					state.error = null
				}
			)
			.addCase(deleteGeoThunk.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload ?? null
			}),
})

export default geoDeleteSlice.reducer
