import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGeoAddState, IGeoModifited } from '../../types'
import { updateGeoThunk } from './geoThunk'

const initialState: IGeoAddState = {
	geo: {
		id: 0,
		code: '',
		name: '',
		currency: '',
		language: '',
	},

	loading: false,
	error: null,
}
const geoUpdateSlice = createSlice({
	name: 'geo',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(updateGeoThunk.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(
				updateGeoThunk.fulfilled,
				(state, action: PayloadAction<IGeoModifited>) => {
					state.geo = action.payload
					state.loading = false
					state.error = null
				}
			)
			.addCase(updateGeoThunk.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload ?? null
			}),
})

export default geoUpdateSlice.reducer
