import { Box } from '@mui/material'

const OfferPageNotFound = () => {
	return (
		<Box
			display={'flex'}
			justifyContent={'center'}
			flexDirection={'column'}
			alignContent={'center'}
			alignItems={'center'}
		>
			<h1>Не найден оффер</h1>
			<h4>Ошибка 404</h4>
		</Box>
	)
}

export default OfferPageNotFound
