import { SpringModal } from '../../../shared/ui/ModalGeo'

const addGeo = () => {
	const some = () => {
		alert('hello world')
	}

	return (
		<div>
			{/* <BasicButtons text={'добавить гео'} callBackFunc={some} /> */}
			<SpringModal
				code={''}
				name={''}
				currency={''}
				language={''}
				isOpenModal={true}
			/>
		</div>
	)
}

export default addGeo
