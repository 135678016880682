import { createSelector } from '@reduxjs/toolkit'

import { IGeoState } from '../../types'

const selectBase = createSelector(
	(state: RootState) => state,
	state => state.geoSlice
)

export const selectGeo = createSelector(
	selectBase,
	(state: IGeoState) => state.geo
)
export const selectGeoLoading = createSelector(
	selectBase,
	(state: IGeoState) => state.loading
)
export const selectGeoError = createSelector(
	selectBase,
	(state: IGeoState) => state.error
)
