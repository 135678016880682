import { Box } from '@mui/material'
import { AddGeo } from '../../../features/addGeo'
import { Table } from '../../../widgets/tableGeo'

export const GeoPage = () => {
	return (
		<Box sx={{ padding: 2 }}>
			<AddGeo />
			<Table />
		</Box>
	)
}
