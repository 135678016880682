import { createSelector } from '@reduxjs/toolkit'

import { IOfferOneState } from '../../types'

const selectBase = createSelector(
	(state: RootState) => state,
	state => state.offerOneSlice
)

export const selectOneOffer = createSelector(
	selectBase,
	(state: IOfferOneState) => state.offer
)
export const selectOfferOneLoading = createSelector(
	selectBase,
	(state: IOfferOneState) => state.loading
)
export const selectOfferOneError = createSelector(
	selectBase,
	(state: IOfferOneState) => state.error
)
