import { Modal as BaseModal } from '@mui/base/Modal'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { css, styled } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import * as React from 'react'
import { IOfferAdd, IOfferMod, IOfferUpdate } from '../../../api/offer/types'
import { useAppDispatch } from '../../../lib/store'
import { DropDown } from '../../DropDown'
import { countries } from '../../DropDown/types'
type IOfferSubmit = Partial<IOfferUpdate> & Partial<IOfferAdd>

interface SpringModalProps extends IOfferMod {
	isOpenModal: boolean
	countries: countries[]
	geoId: number
	callBackFunc?: () => void
	callSubmitAdd?: (data: IOfferAdd) => void
	callSubmitUpdate?: (data: any) => void
}

export default function SpringModal({
	id,
	name,
	GeoName,
	geoId,
	price,
	discount,
	link,
	isOpenModal,
	previewImageUrl,
	countries,
	buttonDescription,
	description,
	callSubmitUpdate,
	callSubmitAdd,
	callBackFunc,
}: SpringModalProps) {
	const [open, setOpen] = React.useState(false)
	const dispatch = useAppDispatch()

	// Объединение всех полей в один стейт

	type IOfferMod = Omit<IOfferUpdate, 'link'> & {
		id: number
		name: string
		geo: {
			GeoName: string
			geoId: number
		}
		geoId: number // Add geoId at the top level as well
		price: string
		discount: string
		previewImageUrl: string
		isOpenModal: boolean
		buttonDescription: string
		description: string
	}

	const [formData, setFormData] = React.useState<IOfferMod>({
		id: id,
		name: name || '',
		geo: {
			GeoName: GeoName || '',
			geoId: geoId || 0,
		},
		geoId: geoId || 0, // Ensure geoId is added at the top level
		price: price || '',
		discount: discount || '',
		previewImageUrl: previewImageUrl || '',
		isOpenModal: isOpenModal || false,
		buttonDescription: buttonDescription || '',
		description: description || '',
	})

	const handleOpen = () => setOpen(true)
	const handleClose = () => {
		setOpen(false)

		if (callBackFunc) {
			callBackFunc()
		}
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target

		// Если изменяется geo, обработаем его отдельно
		if (name === 'GeoName' || name === 'geoId') {
			setFormData(prevState => ({
				...prevState,
				geo: { ...prevState.geo, [name]: value },
			}))
		} else {
			setFormData(prevState => ({ ...prevState, [name]: value }))
		}
	}

	const handleSubmit = () => {
		const { name } = formData

		const newErrors = {
			name: name === '',
		}

		const hasErrors = Object.values(newErrors).some(error => error)

		if (!hasErrors) {
			const { geo, isOpenModal, id, ...some } = formData

			if (callSubmitUpdate) {
				const { geo, isOpenModal, id, ...some } = formData

				// Создаем объект newData с корректными типами
				const newData = { ...some }

				// Теперь вы можете использовать newData для вызова callSubmitUpdate
				callSubmitUpdate(newData)
			} else if (callSubmitAdd) {
				const { id, isOpenModal, geo, ...some } = formData
				const newData: IOfferAdd = {
					...some,
					geoId: geo.geoId,
					buttonDescription: formData.buttonDescription,
					description: formData.description,
				}

				callSubmitAdd(newData)
			}
			// Вызов callback или dispatch на основе наличия id
			handleClose()
		}
	}

	React.useEffect(() => {
		if (!isOpenModal) setOpen(true)
	}, [isOpenModal])

	return (
		<div>
			{isOpenModal && (
				<TriggerButton onClick={handleOpen}>добавить</TriggerButton>
			)}
			<Modal
				aria-labelledby='spring-modal-title'
				aria-describedby='spring-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: StyledBackdrop }}
			>
				<Fade in={open}>
					<ModalContent sx={style}>
						<h2 className='modal-title'>Заполните данные offer</h2>

						<TextField
							label='Оффер'
							name='name'
							value={formData.name}
							onChange={handleChange}
							fullWidth
							margin='normal'
						/>
						<DropDown
							callSetGeo={(arg1: string, arg2: number) => {
								setFormData({
									...formData,
									geoId: arg2,
									geo: {
										...formData.geo,
										GeoName: arg1,
										geoId: arg2,
									},
								})
							}}
							defaultValue={{
								nameCountries: formData.geo.GeoName,
								id: formData.geo.geoId,
							}}
							countries={countries}
						/>

						<TextField
							label='Цена'
							name='price'
							value={formData.price}
							onChange={handleChange}
							fullWidth
							margin='normal'
						/>

						<TextField
							label='Скидка'
							name='discount'
							value={formData.discount}
							onChange={handleChange}
							fullWidth
							margin='normal'
						/>
						<TextField
							label='Изображение'
							name='previewImageUrl'
							value={formData.previewImageUrl}
							onChange={handleChange}
							fullWidth
							margin='normal'
						/>
						{/* <TextField
							label='Ссылка'
							name='link'
							value={formData.link}
							onChange={handleChange}
							fullWidth
							margin='normal'
						/> */}
						<TextField
							label='Описание кнопки'
							name='buttonDescription'
							value={formData.buttonDescription}
							onChange={handleChange}
							fullWidth
							margin='normal'
						/>
						<TextField
							label='Описание оффера'
							name='description'
							value={formData.description}
							onChange={handleChange}
							fullWidth
							margin='normal'
						/>

						<Button variant='contained' onClick={handleSubmit} sx={{ mt: 2 }}>
							Отправить
						</Button>
						<Button onClick={handleClose} variant='outlined' sx={{ mt: 1 }}>
							Закрыть
						</Button>
					</ModalContent>
				</Fade>
			</Modal>
		</div>
	)
}

const Backdrop = React.forwardRef<
	HTMLDivElement,
	{ children: React.ReactElement<any>; open: boolean }
>((props, ref) => {
	const { open, ...other } = props
	return <Fade ref={ref} in={open} {...other} />
})

const Modal = styled(BaseModal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`

interface FadeProps {
	children: React.ReactElement<any>
	in?: boolean
	onClick?: any
	onEnter?: (node: HTMLElement, isAppearing: boolean) => void
	onExited?: (node: HTMLElement, isAppearing: boolean) => void
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
	props,
	ref
) {
	const { in: open, children, onEnter, onExited, ...other } = props
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null as any, true)
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null as any, true)
			}
		},
	})

	return (
		<animated.div ref={ref} style={style} {...other}>
			{children}
		</animated.div>
	)
})

const blue = {
	200: '#99CCFF',
	300: '#66B2FF',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	700: '#0066CC',
}

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
}

const ModalContent = styled('div')(
	({ theme }) => css`
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 500;
		text-align: start;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 8px;
		overflow: hidden;
		background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
		border-radius: 8px;
		border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
		box-shadow: 0 4px 12px
			${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
		padding: 24px;
		color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

		& .modal-title {
			margin: 0;
			line-height: 1.5rem;
			margin-bottom: 8px;
		}

		& .modal-description {
			margin: 0;
			line-height: 1.5rem;
			font-weight: 400;
			color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
			margin-bottom: 4px;
		}
	`
)

const TriggerButton = styled(Button)(
	({ theme }) => css`
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 600;
		font-size: 0.875rem;
		line-height: 1.5;
		padding: 8px 16px;
		border-radius: 8px;
		transition: all 150ms ease;
		cursor: pointer;
		background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
		border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
		color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
		box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

		&:hover {
			background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
			border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
		}

		&:active {
			background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
		}

		&:focus-visible {
			box-shadow: 0 0 0 4px
				${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
			outline: none;
		}
	`
)
