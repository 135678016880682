import { apiInstance } from '../base'
import { IOffer, IOfferAdd, IOfferUpdate } from './types'

const BASE_URL = 'offers'

export const getOffer = async () => {
	return await apiInstance.get<IOffer[]>(`${BASE_URL}`)
}
export const getOneOffer = async (id: number) => {
	return await apiInstance.get<IOffer>(`${BASE_URL}/${id}`)
}

export const addOffer = async (offerData: IOfferAdd) => {
	return await apiInstance.post<IOfferAdd>(`${BASE_URL}`, offerData)
}
export const patchOffer = async (offerData: IOfferUpdate, id: number) => {
	const { id: _, ...dataWithoutId } = offerData // Убираем поле id

	const newData = { ...dataWithoutId, id } // Добавляем id обратно, если нужно
	return await apiInstance.put<any>(`${BASE_URL}/${id}`, dataWithoutId)
}

export const deleteOffer = async (id: number) => {
	return await apiInstance.delete<number>(`${BASE_URL}/${id}`)
}
