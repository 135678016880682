import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IOfferState } from './../../types'

import { IOffer } from '../../../../../shared/api/offer'
import { fetchOfferThunk } from './offerThunk'

const initialState: IOfferState = {
	offer: [
		{
			id: 0,
			name: '',
			geoId: 0,
			price: '',
			discount: '',
			previewImageUrl: '',
			link: '',
			createdAt: '',
			updatedAt: '',
			buttonDescription: '',
			description: '',
			geo: {
				code: '',
				name: '',
				currency: '',
				language: '',
				id: 0,
			},
			Form: [
				{
					id: 0,
					offerId: 0,
					name: '',
					template: '',
					createdAt: '',
					updatedAt: '',
				},
			],
		},
	],

	loading: false,
	error: null,
}
const geoSlice = createSlice({
	name: 'offer',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(fetchOfferThunk.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(
				fetchOfferThunk.fulfilled,
				(state, action: PayloadAction<IOffer[]>) => {
					state.offer = action.payload
					state.loading = false
					state.error = null
				}
			)
			.addCase(fetchOfferThunk.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload ?? null
			}),
})

export default geoSlice.reducer
