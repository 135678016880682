import './fallback.scss'

export const Fallback = () => {
	return (
		<div role='alert' className='fallback'>
			<h1 className='fallback__img'>Не существующий URL страницы</h1>
			Ошибка 404
		</div>
	)
}
