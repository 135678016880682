import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateGeo } from '../../../../../shared/api/geo/geo'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'
import { IGeoModifited } from '../../types'
import { fetchGeo } from '../get/geoThunk'

export const updateGeoThunk = createAsyncThunk<
	IGeoModifited,
	IGeoModifited, // тип для данных geoData
	{ rejectValue: RejectedDataType }
>('geo/updateGeo', async (geoData: IGeoModifited, thunkAPI) => {
	try {
		const response = await updateGeo(geoData)

		// Вызываем другой thunk после успешного выполнения
		thunkAPI.dispatch(fetchGeo())

		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
