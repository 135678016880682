import { IGeoModifited } from '../../../entities/geo/model/types'
import { apiInstance } from '../base'
import { IGeo } from './types'

const BASE_URL = 'geo'

export const getGeo = async () => {
	return await apiInstance.get<IGeo[]>(`${BASE_URL}`)
}
export const addGeo = async (geoData: IGeo) => {
	return await apiInstance.post<IGeo>(`${BASE_URL}`, geoData)
}
export const updateGeo = async (geoData: IGeoModifited) => {
	const { id, ...some } = geoData
	return await apiInstance.put<IGeoModifited>(`${BASE_URL}/${id}`, geoData)
}
export const deleteGeo = async (id: number) => {
	return await apiInstance.delete<IGeo>(`${BASE_URL}/${id}`)
}
