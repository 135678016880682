import { useEffect, useState } from 'react'
import { fetchGeo } from '../../../entities/geo/model/api/get/geoThunk'
import {
	selectGeo,
	selectGeoError,
	selectGeoLoading,
} from '../../../entities/geo/model/api/get/selectors'
import { addOfferThunk } from '../../../entities/offer/model/api/add/offerThunk'
import { IGeo } from '../../../shared/api/geo'
import { IOfferAdd } from '../../../shared/api/offer/types'
import { useAppDispatch, useAppSelector } from '../../../shared/lib/store'
import { countries } from '../../../shared/ui/DropDown/types'
import { SpringModalOffer } from '../../../shared/ui/ModalOffer'

const AddOffer = () => {
	const some = () => {
		alert('hello world')
	}

	const dispatch = useAppDispatch()
	const selectGeoLocal = useAppSelector(selectGeo)
	const selectGeoLoadingLocal = useAppSelector(selectGeoLoading)
	const selectGeoErrorLocal = useAppSelector(selectGeoError)
	const [localGeos, setLocalGeos] = useState<countries[] | null>(null)
	useEffect(() => {
		dispatch(fetchGeo())
	}, [])
	useEffect(() => {
		if (selectGeoLocal) {
			const newResult: countries[] = selectGeoLocal.map((el: IGeo) => {
				const { id, name, ...some } = el
				return { id, nameCountries: name }
			})
			setLocalGeos(newResult)
		}
	}, [selectGeoLocal])
	const callSubmitAdd = (data: IOfferAdd) => {
		dispatch(addOfferThunk(data))
	}

	return (
		<div>
			{/* <BasicButtons text={'добавить гео'} callBackFunc={some} /> */}
			{localGeos && (
				<SpringModalOffer
					id={0}
					name={''}
					GeoName={''}
					geoId={0}
					price={''}
					discount={''}
					link={''}
					previewImageUrl={''}
					buttonDescription={''}
					description={''}
					countries={localGeos}
					callSubmitAdd={callSubmitAdd}
					// id={formData.id}
					// name={formData.name}
					// GeoName={formData.Geo}
					//callSubmit={callSubmit}

					isOpenModal={true}
				/>
			)}
		</div>
	)
}

export default AddOffer
