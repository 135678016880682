import { useEffect, useState } from 'react'
import { fetchGeo } from '../../../entities/geo/model/api/get/geoThunk'
import {
	selectGeo,
	selectGeoError,
	selectGeoLoading,
} from '../../../entities/geo/model/api/get/selectors'
import { deleteOfferThunk } from '../../../entities/offer/model/api/delete/geoThunk'
import { fetchOfferThunk } from '../../../entities/offer/model/api/get/offerThunk'
import { selectOffer } from '../../../entities/offer/model/api/get/selectors'
import { fetchUpdateOfferThunk } from '../../../entities/offer/model/api/update/offerThunk'
import { IGeo } from '../../../shared/api/geo'
import { IOffer } from '../../../shared/api/offer'
import { IOfferMod } from '../../../shared/api/offer/types'
import { useAppDispatch, useAppSelector } from '../../../shared/lib/store'
import { ResponsiveDialog } from '../../../shared/ui/Dialog'
import { countries } from '../../../shared/ui/DropDown/types'
import { SpringModalOffer } from '../../../shared/ui/ModalOffer'
import { UniversalTable } from '../../../shared/ui/table'

interface TableOfferProps {
	searchTerm: string // Новый пропс для поиска
}

const TableOffer = ({ searchTerm }: TableOfferProps) => {
	interface Column {
		id: keyof IOfferMod
		label: string
		minWidth?: number
		align?: 'left' | 'right' | 'center'
		format?: (value: string | number | Date) => string
	}

	const columns: Column[] = [
		{ id: 'name', label: 'Оффер', minWidth: 170, align: 'left' },
		{ id: 'GeoName', label: 'Гео', minWidth: 50, align: 'left' },
		{ id: 'price', label: 'Цена', minWidth: 100, align: 'left' },
		{ id: 'discount', label: 'Скидка (%)', minWidth: 100, align: 'left' },
		{
			id: 'buttonDescription',
			label: 'Текст кнопки',
			minWidth: 100,
			align: 'left',
		},
		{
			id: 'description',
			label: 'Описание',
			minWidth: 100,

			align: 'left',
		},
		{
			id: 'previewImageUrl',
			label: 'Изображение',
			minWidth: 100,
			align: 'left',
		},
		{ id: 'link', label: 'Ссылка', minWidth: 100, align: 'left' },
	]

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(fetchOfferThunk())
	}, [dispatch])

	const selectOfferLocal = useAppSelector(selectOffer)
	const selectGeoLocal = useAppSelector(selectGeo)
	const selectGeoLoadingLocal = useAppSelector(selectGeoLoading)
	const selectGeoErrorLocal = useAppSelector(selectGeoError)

	const [isModal, setIsModal] = useState<boolean>(false)
	const [codeId, setCodeId] = useState<number>(0)
	const [isFindGeo, setIsFindGeo] = useState<boolean>(false)
	const [idForm, setIdForm] = useState<number | null>(null)
	const [formData, seFormData] = useState<IOfferMod | null>(null)
	const [localGeos, setLocalGeos] = useState<countries[] | null>(null)
	const [updatedOffer, setUpdatedOffer] = useState<IOfferMod[] | null>(null)

	useEffect(() => {
		dispatch(fetchGeo())
	}, [])

	useEffect(() => {
		if (selectGeoLocal) {
			const newResult: countries[] = selectGeoLocal.map((el: IGeo) => {
				const { id, name, ...some } = el
				return { id, nameCountries: name }
			})
			setLocalGeos(newResult)
		}
	}, [selectGeoLocal])

	useEffect(() => {
		if (selectOfferLocal && selectOfferLocal.length > 0) {
			const result: IOfferMod[] = selectOfferLocal.map((el: IOffer) => {
				const { createdAt, updatedAt, geo, geoId, Form, ...elNew } = el
				const price = elNew.price
				const discount = elNew.discount
				const GeoName = geo ? geo.name : 'Unknown'
				const validForm = Form ? Form : []
				return {
					...elNew,
					price,
					discount,
					GeoName,
					geo,
					Form: validForm,
				}
			})
			setUpdatedOffer(result)
		}
	}, [selectOfferLocal])

	// Фильтрация по searchTerm
	const filteredOffers = updatedOffer?.filter(
		offer =>
			offer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			offer.GeoName.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const handleDelete = (code: any) => {
		if (code.id) {
			setIsModal(true)
			setCodeId(code.id)
		}
	}

	const handleUpdate = (code: any) => {
		if (updatedOffer && updatedOffer.length > 0) {
			updatedOffer.filter((offer: IOfferMod) => {
				if (offer.id === code.id) {
					setIsFindGeo(true)
					seFormData(offer)
					setIdForm(code.id)
					return
				}
			})
		}
	}

	const callSubmitUpdate = (data: any) => {
		if (idForm) {
			dispatch(fetchUpdateOfferThunk({ data, id: idForm }))
		}
	}

	return (
		<div>
			{selectGeoLoadingLocal === false &&
				selectGeoErrorLocal === null &&
				updatedOffer !== null && (
					<UniversalTable
						onDelete={handleDelete}
						onUpdate={handleUpdate}
						columns={columns}
						typeTable={'offer'}
						rows={filteredOffers || []} // Используем отфильтрованные данные
						title='Оффер'
					/>
				)}
			{isModal && (
				<ResponsiveDialog
					isOpen={isModal}
					callBackFunc={() => {
						dispatch(deleteOfferThunk(codeId))
					}}
					callCloseOpen={() => {
						setIsModal(false)
					}}
				/>
			)}
			{isFindGeo && formData && localGeos && (
				<SpringModalOffer
					{...formData}
					geoId={formData.geo?.id ? formData.geo?.id : 0}
					countries={localGeos}
					callSubmitUpdate={callSubmitUpdate}
					isOpenModal={false}
					callBackFunc={() => {
						seFormData(null)
					}}
				/>
			)}
		</div>
	)
}

export default TableOffer
