import { Box, TextField } from '@mui/material'
import { useState } from 'react'
import AddOffer from '../../../features/addOffer/ui/addOffer'
import TableOffer from '../../../widgets/tableOffer/ui/Table'

const Offer = () => {
	const [searchTerm, setSearchTerm] = useState<string>('')

	// Обработка изменения поля ввода
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value)
	}

	return (
		<Box sx={{ padding: 2 }}>
			<AddOffer />
			{/* Поле для поиска */}
			<TextField
				label='Поиск офферов'
				variant='outlined'
				fullWidth
				margin='normal'
				value={searchTerm}
				onChange={handleSearchChange}
			/>
			{/* Передача searchTerm в TableOffer */}
			<TableOffer searchTerm={searchTerm} />
		</Box>
	)
}

export default Offer
