import { useEffect, useState } from 'react'
import { deleteGeoThunk } from '../../../entities/geo/model/api/delete/geoThunk'
import { fetchGeo } from '../../../entities/geo/model/api/get/geoThunk'
import {
	selectGeo,
	selectGeoError,
	selectGeoLoading,
} from '../../../entities/geo/model/api/get/selectors'
import { IGeo } from '../../../shared/api/geo'
import { useAppDispatch, useAppSelector } from '../../../shared/lib/store'
import { ResponsiveDialog } from '../../../shared/ui/Dialog'
import { SpringModal } from '../../../shared/ui/ModalGeo'
import { UniversalTable } from '../../../shared/ui/table'

const Table = () => {
	interface Column {
		id: keyof IGeo // Ensure id is one of the keys of IGeo
		label: string
		minWidth?: number
		align?: 'left' | 'right' | 'center'
		format?: (value: string | number | Date) => string
	}

	const columns: Column[] = [
		{ id: 'code', label: 'Код страны (номер)', minWidth: 50, align: 'left' },
		{ id: 'name', label: 'Имя', minWidth: 170, align: 'left' },
		{ id: 'currency', label: 'Валюта', minWidth: 100, align: 'left' },
		{ id: 'language', label: 'Язык', minWidth: 100, align: 'left' },
	]

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(fetchGeo())
	}, [dispatch])

	const selectGeoLocal = useAppSelector(selectGeo)
	const selectGeoLoadingLocal = useAppSelector(selectGeoLoading)
	const selectGeoErrorLocal = useAppSelector(selectGeoError)
	const [isModal, setIsModal] = useState<boolean>(false)
	const [codeId, setCodeId] = useState<number>(0)
	const [isFindGeo, setIsFindGeo] = useState<boolean>(false)
	const [idGeo, setIdGeo] = useState<number | null>(null)
	const [geoData, setGeoData] = useState<IGeo | null>(null)
	const handleDelete = (code: any) => {
		if (code.id) {
			setIsModal(true)
			setCodeId(code.id)
		}
		// Реализуйте логику удаления, например, вызов API для удаления
	}
	const handleUpdate = (code: any) => {
		selectGeoLocal.filter((geo: IGeo) => {
			if (geo.code === code.code) {
				setIsFindGeo(true)
				setGeoData(geo)
				setIdGeo(code.id)
				return
				//SpringModal
			}
		})
	}

	return (
		<div>
			{selectGeoLoadingLocal === false && selectGeoErrorLocal === null && (
				<UniversalTable
					onDelete={handleDelete}
					onUpdate={handleUpdate}
					columns={columns}
					typeTable='geo'
					rows={selectGeoLocal}
					title='ГЕО'
				/>
			)}
			{isModal && (
				<ResponsiveDialog
					isOpen={isModal}
					callBackFunc={() => {
						dispatch(deleteGeoThunk(codeId))
					}}
					callCloseOpen={() => {
						setIsModal(false)
					}}
				/>
			)}
			{isFindGeo && geoData && (
				<SpringModal
					id={idGeo}
					code={geoData.code}
					language={geoData.language}
					currency={geoData.currency}
					name={geoData.name}
					isOpenModal={false}
					callBackFunc={() => {
						setGeoData(null)
					}}
				/>
			)}
		</div>
	)
}

export default Table
