import { configureStore } from '@reduxjs/toolkit'
import geoAddSlice from '../entities/geo/model/api/add/geoSlice'
import geoDeleteSlice from '../entities/geo/model/api/delete/geoSlice'
import geoSlice from '../entities/geo/model/api/get/geoSlice'
import geoUpdateSlice from '../entities/geo/model/api/update/geoSlice'
import offerSlice from '../entities/offer/model/api/get/offerSlice'
import offerOneSlice from '../entities/offer/model/api/getOne/offerSlice'
const store = configureStore({
	reducer: {
		geoSlice,
		geoAddSlice,
		geoDeleteSlice,
		offerSlice,
		geoUpdateSlice,
		offerOneSlice,
	},
})
export default store
