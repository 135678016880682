import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { IGeo } from '../../api/geo'
import { IOfferMod } from '../../api/offer/types'

interface Column {
	id: keyof IGeo | keyof IOfferMod
	label: string
	minWidth?: number
	align?: 'left' | 'right' | 'center'
	format?: (value: number | string) => string
}

interface UniversalTableProps {
	columns: Column[]
	rows: IGeo[] | IOfferMod[]
	title?: string
	onDelete: (code: any) => void // Функция для обработки удаления
	onUpdate: (code: any) => void
	typeTable: 'geo' | 'offer'
}

export const UniversalTable: React.FC<UniversalTableProps> = ({
	columns,
	rows,
	title,
	onDelete,
	onUpdate,
	typeTable,
}) => {
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null)
	const [anchorElText, setAnchorElText] =
		React.useState<HTMLAnchorElement | null>(null)
	const [hoverImage, setHoverImage] = React.useState<string | null>(null)

	const handlePopoverOpen = (
		event: React.MouseEvent<HTMLAnchorElement>,
		imageUrl: string
	) => {
		setAnchorEl(event.currentTarget)
		setHoverImage(imageUrl)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
		setHoverImage(null)
	}

	const open = Boolean(anchorEl)
	const openText = Boolean(anchorElText)

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}
	const [hoverText, setHoverText] = React.useState<string | null>(null)
	const handlePopoverTextOpen = (
		event: React.MouseEvent<HTMLAnchorElement>,
		text: string
	) => {
		setAnchorElText(event.currentTarget)
		setHoverText(text)
	}

	const handlePopoverTextClose = () => {
		setAnchorElText(null)
		setHoverText(null)
	}
	function extractNumberFromUrl(url: string) {
		// Используем регулярное выражение для поиска числа в конце строки
		const match = url.match(/(\d+)(?=\/?$)/)

		// Если нашли совпадение, возвращаем число, иначе null
		return match ? match[0] : null
	}

	return (
		<Paper sx={{ width: '80%' }}>
			{title && <h2 style={{ padding: '16px' }}>{title}</h2>}
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label='sticky table'>
					<TableHead>
						<TableRow>
							{columns.map(column => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, rowIndex) => (
								<TableRow hover role='checkbox' tabIndex={-1} key={rowIndex}>
									{columns.map(column => {
										const value =
											typeTable === 'geo'
												? (row as IGeo)[column.id as keyof IGeo]
												: (row as IOfferMod)[column.id as keyof IOfferMod]

										return (
											<TableCell key={column.id} align={column.align}>
												{column.id === 'link' && typeof value === 'string' ? (
													<Button
														variant='contained'
														color='primary'
														href={`${
															process.env.REACT_APP_FRONTEND
														}/offers/${extractNumberFromUrl(value)}`}
														target='_blank'
														rel='noopener noreferrer'
													>
														Открыть
													</Button>
												) : column.id === 'description' &&
												  typeof value === 'string' ? (
													<a
														href='#'
														onMouseEnter={e => handlePopoverTextOpen(e, value)}
														onMouseLeave={handlePopoverTextClose}
													>
														{value.length > 30
															? `${value.slice(0, 30)}...`
															: value}
													</a>
												) : column.label === 'Изображение' &&
												  typeof value === 'string' ? (
													<a
														href={value}
														target='_blank'
														rel='noopener noreferrer'
														onMouseEnter={e => handlePopoverOpen(e, value)}
														onMouseLeave={handlePopoverClose}
													>
														{value.length > 15
															? `${value.slice(0, 15)}...`
															: value}
													</a>
												) : column.format && typeof value === 'number' ? (
													column.format(value)
												) : typeof value === 'string' ||
												  typeof value === 'number' ? (
													value
												) : Array.isArray(value) ? (
													'Array of Forms'
												) : (
													'Geo Object'
												)}
											</TableCell>
										)
									})}
									<TableCell>
										<IconButton
											onClick={() => {
												onDelete(row)
											}}
											color='error'
										>
											<DeleteIcon />
										</IconButton>
										<IconButton
											onClick={() => {
												onUpdate(row)
											}}
										>
											<RefreshIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component='div'
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Popover
				sx={{ pointerEvents: 'none' }}
				open={openText}
				anchorEl={anchorElText}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverTextClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 2 }}>
					{hoverText || 'No description available'}
				</Typography>
			</Popover>

			<Popover
				sx={{ pointerEvents: 'none' }}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 2 }}>
					{hoverImage ? (
						<img
							src={hoverImage || undefined}
							alt='Preview'
							style={{ maxWidth: '300px', maxHeight: '300px' }}
						/>
					) : (
						'No image available'
					)}
				</Typography>
			</Popover>
		</Paper>
	)
}
