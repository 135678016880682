import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'
import { IDialog } from './types'

export const ResponsiveDialog = ({ isOpen, callBackFunc, callCloseOpen }: IDialog) => {
	const [open, setOpen] = React.useState(false)
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	React.useEffect(() => {
		setOpen(isOpen)
	}, [isOpen])

	const handleClose = () => {
		setOpen(false)
		callCloseOpen()
	}
	const isAgree = () => {
		callBackFunc()
		callCloseOpen()
		setOpen(false)
	}

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby='responsive-dialog-title'
			>
				<DialogTitle id='responsive-dialog-title'>
					{'Удалить данные?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Вы действительно хотите удалить данные гео?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Нет
					</Button>
					<Button onClick={isAgree} autoFocus>
						Да
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}
