import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteGeo } from '../../../../../shared/api/geo/geo'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'
import { fetchGeo } from '../get/geoThunk'

export const deleteGeoThunk = createAsyncThunk<
	number, // Возвращаемый тип (id после удаления)
	number, // Аргумент для передачи в thunk
	{ rejectValue: RejectedDataType }
>('geo/DeleteGeo', async (id, thunkAPI) => {
	try {
		// Вызываем API для удаления гео
		const response = await deleteGeo(id)

		// Вызываем другой thunk для обновления списка после удаления
		thunkAPI.dispatch(fetchGeo())

		// Возвращаем id как результат успешного выполнения
		return id
	} catch (err: unknown) {
		// Обработка ошибок с помощью известного типа
		const knownError = err as ErrorType

		// Возвращаем отклонённое действие с сообщением об ошибке и статусом
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
