import { useParams } from 'react-router-dom'
import { OfferLogic } from '../../../widgets/offer'

const OfferPage = () => {
	const params = useParams<{ id: string }>() // указываем тип параметра как строку
	const id = Number(params.id) // Преобразуем id в число

	return (
		<div>
			<OfferLogic params={id} />
		</div>
	)
}

export default OfferPage
