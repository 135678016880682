import { createAsyncThunk } from '@reduxjs/toolkit'
import { IOffer } from '../../../../../shared/api/offer'
import { getOneOffer } from '../../../../../shared/api/offer/offer'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

export const fetchOfferOneThunk = createAsyncThunk<
	IOffer,
	number, // id типа number
	{ rejectValue: RejectedDataType }
>('offer/fetchOffer', async (id, thunkAPI) => {
	try {
		const response = await getOneOffer(id) // Используем id, переданный в функцию
		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
