import { Modal as BaseModal } from '@mui/base/Modal'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { css, styled } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import * as React from 'react'
import { addGeoThunk } from '../../../../entities/geo/model/api/add/geoThunk'
import { updateGeoThunk } from '../../../../entities/geo/model/api/update/geoThunk'
import { IGeoModifited } from '../../../../entities/geo/model/types'
import { useAppDispatch } from '../../../lib/store'

interface IGeo {
	id?: number | null
	readonly code: string
	readonly name: string
	readonly currency: string
	readonly language: string
}

interface SpringModalProps extends IGeo {
	isOpenModal: boolean // Add this to the props
	callBackFunc?: () => void
}

export default function SpringModal({
	id,
	code,
	name,
	currency,
	language,
	isOpenModal,
	callBackFunc,
}: SpringModalProps) {
	const [open, setOpen] = React.useState(false)

	const dispatch = useAppDispatch()
	const [geoData, setGeoData] = React.useState<IGeoModifited>({
		id: 0,
		code: '',
		name: '',
		currency: '',
		language: '',
	})
	React.useEffect(() => {
		setGeoData({ id: 0, code, currency, name, language })
	}, [code, currency, name, language])
	const [errors, setErrors] = React.useState({
		code: false,
		name: false,
		currency: false,
		language: false,
	})

	const handleOpen = () => setOpen(true)
	const handleClose = () => {
		setOpen(false)

		if (callBackFunc) {
			callBackFunc()
		}
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		setGeoData({ ...geoData, [name]: value })
		setErrors({ ...errors, [name]: value === '' }) // Убираем ошибку, если поле заполнено
	}

	const handleSubmit = () => {
		const { code, name, currency, language } = geoData

		// Проверка на пустые поля
		const newErrors = {
			code: code === '',
			name: name === '',
			currency: currency === '',
			language: language === '',
		}

		setErrors(newErrors)

		const hasErrors = Object.values(newErrors).some(error => error)

		if (!hasErrors) {
			if (callBackFunc && id) {
				geoData.id = id
				dispatch(updateGeoThunk(geoData))
				handleClose()
				return
			}
			dispatch(addGeoThunk(geoData))
			setGeoData({
				id: 0,
				code: '',
				name: '',
				currency: '',
				language: '',
			})
			handleClose()
		}
	}
	React.useEffect(() => {
		if (isOpenModal === false) {
			setOpen(true)
		}
	}, [isOpenModal])

	return (
		<div>
			{isOpenModal && (
				<TriggerButton onClick={handleOpen}>добавить</TriggerButton>
			)}
			<Modal
				aria-labelledby='spring-modal-title'
				aria-describedby='spring-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: StyledBackdrop }}
			>
				<Fade in={open}>
					<ModalContent sx={style}>
						<h2 className='modal-title'>Заполните данные GEO</h2>
						<TextField
							label='Код страны'
							name='code'
							value={geoData.code}
							onChange={handleChange}
							fullWidth
							margin='normal'
							error={errors.code}
							helperText={errors.code ? 'Поле обязательно для заполнения' : ''}
						/>
						<TextField
							label='Имя'
							name='name'
							value={geoData.name}
							onChange={handleChange}
							fullWidth
							margin='normal'
							error={errors.name}
							helperText={errors.name ? 'Поле обязательно для заполнения' : ''}
						/>
						<TextField
							label='Валюта'
							name='currency'
							value={geoData.currency}
							onChange={handleChange}
							fullWidth
							margin='normal'
							error={errors.currency}
							helperText={
								errors.currency ? 'Поле обязательно для заполнения' : ''
							}
						/>
						<TextField
							label='Язык'
							name='language'
							value={geoData.language}
							onChange={handleChange}
							fullWidth
							margin='normal'
							error={errors.language}
							helperText={
								errors.language ? 'Поле обязательно для заполнения' : ''
							}
						/>
						<Button variant='contained' onClick={handleSubmit} sx={{ mt: 2 }}>
							Отправить
						</Button>
						<Button onClick={handleClose} variant='outlined' sx={{ mt: 1 }}>
							Закрыть
						</Button>
					</ModalContent>
				</Fade>
			</Modal>
		</div>
	)
}

// Остальная часть кода (Backdrop, Modal, Fade, стили) остается без изменений

const Backdrop = React.forwardRef<
	HTMLDivElement,
	{ children: React.ReactElement<any>; open: boolean }
>((props, ref) => {
	const { open, ...other } = props
	return <Fade ref={ref} in={open} {...other} />
})

const Modal = styled(BaseModal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`

interface FadeProps {
	children: React.ReactElement<any>
	in?: boolean
	onClick?: any
	onEnter?: (node: HTMLElement, isAppearing: boolean) => void
	onExited?: (node: HTMLElement, isAppearing: boolean) => void
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
	props,
	ref
) {
	const { in: open, children, onEnter, onExited, ...other } = props
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null as any, true)
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null as any, true)
			}
		},
	})

	return (
		<animated.div ref={ref} style={style} {...other}>
			{children}
		</animated.div>
	)
})

const blue = {
	200: '#99CCFF',
	300: '#66B2FF',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	700: '#0066CC',
}

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
}

const ModalContent = styled('div')(
	({ theme }) => css`
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 500;
		text-align: start;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 8px;
		overflow: hidden;
		background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
		border-radius: 8px;
		border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
		box-shadow: 0 4px 12px
			${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
		padding: 24px;
		color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

		& .modal-title {
			margin: 0;
			line-height: 1.5rem;
			margin-bottom: 8px;
		}

		& .modal-description {
			margin: 0;
			line-height: 1.5rem;
			font-weight: 400;
			color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
			margin-bottom: 4px;
		}
	`
)

const TriggerButton = styled(Button)(
	({ theme }) => css`
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 600;
		font-size: 0.875rem;
		line-height: 1.5;
		padding: 8px 16px;
		border-radius: 8px;
		transition: all 150ms ease;
		cursor: pointer;
		background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
		border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
		color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
		box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

		&:hover {
			background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
			border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
		}

		&:active {
			background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
		}

		&:focus-visible {
			box-shadow: 0 0 0 4px
				${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
			outline: none;
		}
	`
)
