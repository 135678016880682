import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteOffer } from '../../../../../shared/api/offer/offer'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'
import { fetchOfferThunk } from '../get/offerThunk'

export const deleteOfferThunk = createAsyncThunk<
	number, // Возвращаемый тип (id после удаления)
	number, // Аргумент для передачи в thunk
	{ rejectValue: RejectedDataType }
>('geo/DeleteGeo', async (id, thunkAPI) => {
	try {
		// Вызываем API для удаления гео
		const response = await deleteOffer(id)

		// Вызываем другой thunk для обновления списка после удаления
		thunkAPI.dispatch(fetchOfferThunk())

		// Возвращаем id как результат успешного выполнения
		return response
	} catch (err: unknown) {
		// Обработка ошибок с помощью известного типа
		const knownError = err as ErrorType

		// Возвращаем отклонённое действие с сообщением об ошибке и статусом
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
