import { AppBar, Box, Button, Toolbar } from '@mui/material'
import { Link, Outlet } from 'react-router-dom'

export const Layout = () => {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='static'>
				<Toolbar>
					<Button color='inherit' component={Link} to='/geo'>
						Geo
					</Button>
					<Button color='inherit' component={Link} to='/offer'>
						Offer
					</Button>
				</Toolbar>
			</AppBar>
			<main>
				<Outlet />
			</main>
		</Box>
	)
}
