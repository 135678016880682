import { createAsyncThunk } from '@reduxjs/toolkit'
import { IOffer } from '../../../../../shared/api/offer'
import { getOffer } from '../../../../../shared/api/offer/offer'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

export const fetchOfferThunk = createAsyncThunk<
	IOffer[],
	void,
	{ rejectValue: RejectedDataType }
>('offer/fetchOffer', async (_, thunkAPI) => {
	try {
		const response = await getOffer()
		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
