import { Modal as BaseModal } from '@mui/base/Modal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Button, TextField } from '@mui/material'
import { styled } from '@mui/system'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { fetchOfferOneThunk } from '../../../entities/offer/model/api/getOne/offerThunk'

import { useNavigate } from 'react-router-dom'
import {
	selectOfferOneError,
	selectOfferOneLoading,
	selectOneOffer,
} from '../../../entities/offer/model/api/getOne/selectors'
import { useAppDispatch, useAppSelector } from '../../../shared/lib/store'
import { IOfferWidget } from '../../../shared/types/types'
const OfferLogic: React.FC<IOfferWidget> = ({ params }) => {
	const dispatch = useAppDispatch()

	const selectOneOfferLocal = useAppSelector(selectOneOffer)
	const selectOfferOneLoadingLocal = useAppSelector(selectOfferOneLoading)
	const selectOfferOneErrorLocal = useAppSelector(selectOfferOneError)

	useEffect(() => {
		dispatch(fetchOfferOneThunk(params))
	}, [params])

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [name, setName] = useState<null | string>(null)
	const [isClicked, setIsClicked] = useState<boolean>(false)
	const [phone, setPhone] = useState<string | null>(null)

	const handleChange = (event: any) => {
		setPhone(event.target.value)
		setIsClicked(false)
	}
	useEffect(() => {
		if (phone && name && isClicked) {
			setIsModalOpen(true)
		}
	}, [phone, name, isClicked])

	const handleButtonClick = () => {
		setIsClicked(true)
		// setIsValid(isValidNow)
		// if (isValidNow) {
		// 	setIsModalOpen(true)
		// }
	}

	const handleCloseModal = () => {
		setIsModalOpen(false)
		setIsClicked(false)
	}
	function isEmptyString(str: string): boolean {
		return str.trim().length === 0
	}
	const navigate = useNavigate()
	useEffect(() => {
		if (!selectOneOfferLocal) {
			navigate('/offers/404')
		}
	}, [
		selectOneOfferLocal,
		selectOfferOneErrorLocal,
		selectOfferOneLoadingLocal,
	])

	return (
		<Box display='flex' justifyContent='center' alignItems='center'>
			{selectOfferOneErrorLocal === null &&
			selectOfferOneLoadingLocal === false ? (
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					sx={{ width: '50%', maxWidth: '600px', textAlign: 'center' }}
				>
					<img
						src={selectOneOfferLocal.previewImageUrl}
						alt={selectOneOfferLocal.name}
						style={{ width: '25%', marginBottom: '20px' }}
					/>
					<p>{selectOneOfferLocal.description}</p>

					<Box
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						gap={2}
						sx={{ width: '100%', maxWidth: '400px', marginBottom: '20px' }}
					>
						<TextField
							error={
								(name == null && isClicked === true) ||
								(name != null && isEmptyString(name) && isClicked === true)
									? true
									: false
							}
							onChange={e => {
								setName(e.target.value)
								setIsClicked(false)
							}}
							value={name}
							placeholder='text...'
						/>
						<Box
							display='flex'
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
						>
							<Box
								component='section'
								sx={{
									p: 1,
									border: '1px dashed grey',
									minWidth: '100px',
									textAlign: 'center',
								}}
							>
								{selectOneOfferLocal.geo?.language}
							</Box>

							<TextField
								id='outlined-basic'
								label={'+' + selectOneOfferLocal.geo?.code}
								variant='outlined'
								value={phone}
								error={
									(phone == null && isClicked === true) ||
									(phone !== null && isEmptyString(phone) && isClicked === true)
										? true
										: false
								}
								onChange={handleChange}
								sx={{ minWidth: '100px' }}
							/>
						</Box>
					</Box>

					<Button onClick={() => handleButtonClick()} variant='contained'>
						{selectOneOfferLocal.buttonDescription}
					</Button>

					<Modal
						open={isModalOpen}
						onClose={handleCloseModal}
						slots={{ backdrop: StyledBackdrop }}
					>
						<Box display='flex' justifyContent='center' alignItems='center'>
							<Box
								sx={{
									width: '200px',
									bgcolor: 'background.paper',
									borderRadius: '8px',
									p: 4,
									textAlign: 'center',
									boxShadow: 24,
								}}
							>
								<CheckCircleIcon
									color='success'
									sx={{ fontSize: '3rem', mb: 2 }}
								/>
							</Box>
						</Box>
					</Modal>
				</Box>
			) : (
				<div>loading...</div>
			)}
		</Box>
	)
}
const Backdrop = React.forwardRef<
	HTMLDivElement,
	{ open?: boolean; className: string }
>((props, ref) => {
	const { open, className, ...other } = props
	return (
		<div
			className={clsx({ 'base-Backdrop-open': open }, className)}
			ref={ref}
			{...other}
		/>
	)
})

Backdrop.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
}

const blue = {
	200: '#99CCFF',
	300: '#66B2FF',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	700: '#0066CC',
}

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
}

const Modal = styled(BaseModal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`

export default OfferLogic
